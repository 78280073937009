import { useEffect } from 'react'

// Define the shape of the Rum configuration using type aliases
export type RumConfig = {
  ddTraceEnabled?: boolean
  ddAppId?: string
  ddAppToken?: string
  ddEnv?: string
  ddService?: string
  ddVersion?: string
  ddSite?: string
  ddSampleRate?: number
  trackResources?: boolean
  trackInteractions?: boolean
}

// Define the shape of the datadogRum instance using type aliases
export type DatadogRum = {
  init: (config: {
    applicationId: string
    clientToken: string
    site: string
    service: string
    env: string
    version: string
    sessionSampleRate: number
    trackResources?: boolean
    trackInteractions?: boolean
  }) => void
}

// Define the props for the Rum component using type aliases
type RumProps = {
  config?: RumConfig
  rum?: DatadogRum | null
}

const Rum = ({ config = {}, rum = null }: RumProps) => {
  // Destructure configuration with defaults from environment variables
  const {
    ddTraceEnabled = process.env.NEXT_PUBLIC_DD_TRACE_ENABLED === 'true',
    ddAppId = process.env.NEXT_PUBLIC_DD_RUM_APP_ID || '',
    ddAppToken = process.env.NEXT_PUBLIC_DD_RUM_APP_TOKEN || '',
    ddEnv = process.env.NEXT_PUBLIC_DD_ENV || 'local',
    ddService = process.env.NEXT_PUBLIC_DD_SERVICE || 'contentx-marketing-frontend',
    ddVersion = process.env.NEXT_PUBLIC_DD_VERSION || '0.0.0',
    ddSite = 'datadoghq.com',
    ddSampleRate = 5, // Reduced sample rate to 5%
    trackResources = false, // Disable resource tracking by default
    trackInteractions = false, // Disable interaction tracking by default
  } = config

  const isProduction = ddEnv === 'pdm-prd' // Ensure it's only enabled in production

  const shouldInitializeRum = ddTraceEnabled && isProduction

  useEffect(() => {
    if (shouldInitializeRum) {
      const initializeRum = async () => {
        try {
          // Use injected datadogRum or dynamically import it
          const datadogRumInstance: DatadogRum = rum || (await import('@datadog/browser-rum-slim')).datadogRum

          datadogRumInstance.init({
            applicationId: ddAppId,
            clientToken: ddAppToken,
            site: ddSite,
            service: ddService,
            env: ddEnv,
            version: ddVersion,
            sessionSampleRate: ddSampleRate,
            trackResources, // Apply selective tracking
            trackInteractions,
          })
        } catch (e) {
          console.error('Datadog RUM injection failed:', e)
        }
      }

      initializeRum()
    }
  }, [
    shouldInitializeRum,
    ddAppId,
    ddAppToken,
    ddSite,
    ddService,
    ddEnv,
    ddVersion,
    ddSampleRate,
    trackResources,
    trackInteractions,
    rum,
  ])

  return null
}

export default Rum
